import React from "react";
import {IDepartureItem} from "../../types";
import {useCurrentTime} from "./hooks";
import {formatCountdown} from "../../../utils";
import TrainIcon from "../TrainIcon";

import './styles.scss';

interface ITrainIconProps {
    data: IDepartureItem;
}

const DepartureItem = (props: ITrainIconProps) => {
    const {data} = props;

    const currentTime = useCurrentTime();

    if (data.realtimeDepartureTime < currentTime || data.cancelled) {
        return <></>
    }

    return (
        <div className="DepartureItem">
            <div className="destination">
                <div className="destination-icon">
                    <TrainIcon type={data.transportType} label={data.label} />
                </div>
                <div className="destination-name">{data.destination}</div>
            </div>
            <div className="messages">{data.messages.join('; ')}</div>
            <div className="timeleft">{formatCountdown(data.realtimeDepartureTime - currentTime)}</div>
        </div>
    );
}

export default DepartureItem;
