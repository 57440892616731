import {IDepartureItem} from "./types";
import {useEffect, useState} from "react";

export function useCurrentDepartures(globalId: string, limit = 10): IDepartureItem[] {
    const [version, setVersion] = useState(0);
    const [departures, setDepartures] = useState<IDepartureItem[]>([]);

    useEffect(() => {
        const controller = new AbortController();

        fetch(`https://www.mvg.de/api/fib/v2/departure?globalId=${globalId}&limit=${limit}`, {signal: controller.signal})
            .then(res => res.json() as Promise<IDepartureItem[]>)
            .then(res => setDepartures(res.sort((a, b) => a.realtimeDepartureTime - b.realtimeDepartureTime)))

        return () => {
            controller.abort();
        }
    }, [globalId, limit, version]);

    useEffect(() => {
        const interval = setInterval(() => {
            setVersion(prev => prev + 1);
        }, 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    return departures;
}
