import React, {useEffect} from 'react';
import './styles.scss';
import {useCurrentDepartures} from "./hooks";
import DepartureItem from "./components/DepartureItem";

const App = () => {
    const departures = useCurrentDepartures('de:09162:1230', 10);

    useEffect(() => {
        const interval = setInterval(() => {
            window.location.reload();
        }, 3600 * 4 * 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="App">
            {departures.map(departure => (
                <DepartureItem key={departure.destination + departure.realtimeDepartureTime} data={departure} />
            ))}
        </div>
    );
}

export default App;
