import {useEffect, useState} from "react";

export function useCurrentTime(precision = 1000) {
    const [time, setTime] = useState(Date.now());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return time;
}
