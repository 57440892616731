import React from "react";

import './styles.scss';
import {TransportType} from "../../types";

interface ITrainIconProps {
    label: string;
    type: TransportType;
}

const TrainIcon = (props: ITrainIconProps) => {
    const {label, type} = props;

    return <div className={`TrainIcon ${type}-${label}`}>
        {label}
    </div>
}

export default TrainIcon;
